@if (isLoading) {
    <h1 jhiTranslate="artemisApp.userSettings.sshSettings"></h1>
} @else {
    @if (!isCreateMode) {
        <h1 jhiTranslate="artemisApp.userSettings.sshSettingsPage.sshKeyDetails"></h1>
    } @else {
        <h1 jhiTranslate="artemisApp.userSettings.sshSettingsPage.addNewSshKey"></h1>
    }

    <div class="list-group d-block">
        <!-- Viewing existing key and creating a new key -->
        <div class="list-group-item">
            <div class="d-flex flex-wrap">
                <p>
                    <span class="large-text" jhiTranslate="artemisApp.userSettings.sshSettingsPage.whatToUseSSHForInfo"></span>
                    <jhi-documentation-link [documentationType]="documentationType" [displayString]="'artemisApp.userSettings.sshSettingsPage.learnMore'"> </jhi-documentation-link>
                </p>
            </div>

            @if (isCreateMode) {
                <div>
                    <p class="large-text" jhiTranslate="artemisApp.userSettings.sshSettingsPage.key"></p>
                </div>
            } @else {
                <div>
                    <p class="large-text">{{ displayedKeyLabel }}</p>
                </div>
            }
            <div>
                <textarea class="form-control" rows="10" [readonly]="!isCreateMode" [(ngModel)]="displayedSshKey" data-testid="sshKeyField"></textarea>
            </div>

            <!-- input fields for key creation -->
            @if (isCreateMode) {
                <div class="d-flex flex-wrap mb-4">
                    <p class="small-text">
                        <span jhiTranslate="artemisApp.userSettings.sshSettingsPage.alreadyHaveKey"> </span>
                        <code> {{ copyInstructions }} </code>
                    </p>
                </div>

                <div class="mb-4">
                    <p class="large-text" jhiTranslate="artemisApp.userSettings.sshSettingsPage.label"></p>
                    <textarea class="form-control small-text-area" rows="1" [readonly]="!isCreateMode" [(ngModel)]="displayedKeyLabel" maxlength="50">></textarea>
                    <p class="small-text">
                        <span jhiTranslate="artemisApp.userSettings.sshSettingsPage.commentUsedAsLabel"> </span>
                    </p>
                </div>

                <!-- Expiry date settings -->
                <div>
                    <span class="large-text" jhiTranslate="artemisApp.userSettings.sshSettingsPage.expiry.title"></span>
                    <p jhiTranslate="artemisApp.userSettings.sshSettingsPage.expiry.info"></p>
                </div>
                <div>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="activationOption" [(ngModel)]="selectedOption" value="doNotUseExpiration" />
                        <span jhiTranslate="artemisApp.userSettings.sshSettingsPage.expiry.doNotExpire"></span>
                    </label>
                </div>
                <div>
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="activationOption" [(ngModel)]="selectedOption" value="useExpiration" />
                        <span jhiTranslate="artemisApp.userSettings.sshSettingsPage.expiry.expireAutomatically"></span>
                    </label>
                </div>

                <!-- expiry date date picker -->
                @if (selectedOption === 'useExpiration') {
                    <div class="mt-4">
                        <div class="col-sm-4">
                            <jhi-date-time-picker
                                labelName="{{ 'artemisApp.userSettings.sshSettingsPage.expiryDate' | artemisTranslate }}"
                                [(ngModel)]="displayedExpiryDate"
                                [shouldDisplayTimeZoneWarning]="false"
                                [min]="currentDate"
                                [requiredField]="true"
                                (valueChange)="validateExpiryDate()"
                                name="expiryDate"
                                id="expiryDate"
                            />
                        </div>
                    </div>
                }
            } @else {
                <!-- View for viewing key details -->
                @if (displayCreationDate) {
                    <div class="text-and-date large-text mt-4">
                        <div jhiTranslate="artemisApp.userSettings.sshSettingsPage.createdOn"></div>
                        <div>
                            {{ displayCreationDate | artemisDate: 'long-date' }}
                        </div>
                    </div>
                }
                @if (displayedLastUsedDate) {
                    <div class="text-and-date large-text mt-2">
                        <div jhiTranslate="artemisApp.userSettings.sshSettingsPage.lastUsedOn"></div>
                        <div>
                            {{ displayedLastUsedDate | artemisDate: 'long-date' }}
                        </div>
                    </div>
                }
                @if (displayedExpiryDate) {
                    <div class="text-and-date large-text mt-2">
                        <div jhiTranslate="artemisApp.userSettings.sshSettingsPage.expiresOn"></div>
                        <div>
                            {{ displayedExpiryDate | artemisDate: 'long-date' }}
                        </div>
                    </div>
                }
            }
            <div class="col col-auto text-right mt-4">
                @if (isCreateMode) {
                    <div class="btn-group" role="group" aria-label="Actions">
                        <jhi-button
                            [disabled]="
                                !(displayedSshKey && ((selectedOption === 'useExpiration' && displayedExpiryDate && isExpiryDateValid) || selectedOption !== 'useExpiration'))
                            "
                            [btnType]="ButtonType.PRIMARY"
                            [btnSize]="ButtonSize.SMALL"
                            [icon]="faSave"
                            [title]="'artemisApp.userSettings.sshSettingsPage.saveSshKey'"
                            (onClick)="saveSshKey()"
                            data-testid="saveSshKeyButton"
                        />
                    </div>
                }
                <div class="btn-group" role="group" aria-label="Actions">
                    <jhi-button
                        [btnType]="ButtonType.PRIMARY"
                        [btnSize]="ButtonSize.SMALL"
                        [title]="isCreateMode ? 'artemisApp.userSettings.sshSettingsPage.cancelSavingSshKey' : 'artemisApp.userSettings.sshSettingsPage.back'"
                        (onClick)="goBack()"
                    />
                </div>
            </div>
        </div>
    </div>
}
